(function($) {

  // init main menu
  var $mainmenu = $('#menu-hoofdmenu');
  $mainmenu.addClass('collapsed');

  // Main navigation Hamburger toggler
  $('.menu-toggler').click(function(){
    if ( $mainmenu.hasClass('expanded') ) {
      $mainmenu.removeClass('expanded');
      $mainmenu.addClass('collapsed');
    } else {
      $mainmenu.addClass('expanded');
      $mainmenu.removeClass('collapsed');
    }
  });

  // Do not follow Theme main page in main menu
  $('#menu-item-30 > a').click(function(e) {
    console.log('klik');
    e.preventDefault();
  });

})(jQuery);